<template>
  <div class="container">
    <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white">
      <div class="d-flex align-items-center flex-shrink-0 p-3 link-dark text-decoration-none border-bottom">
        <input class="fs-5 fw-semibold" v-model="username"/>
      </div>
      <div class="list-group list-group-flush border-bottom scrollarea">
        <div class="list-group-item list-group-item-action py-3 lh-tight"
             v-for="message in messages"
        >
          <div class="d-flex w-100 align-items-center justify-content-between">
            <strong class="mb-1">{{ message.username }}</strong>
          </div>
          <div class="col-10 mb-1 small">{{ message.body }}</div>
          <div class="text-center ma-2">
           <v-snackbar
           timeout="4000"
            v-model="snackbar"
             absolute
             text
             color="primary"
             outlined
           >
             <strong style="color:'primary'; font-size:16px;">gg{{ message.username }}</strong> <br />
             <p style="color:gray; font-size:14px;">ss{{ message.body }}</p>
             <template v-slot:action="{ attrs }">
               <v-btn
                 color="gray"
                 text
                 v-bind="attrs"
                 @click="snackbar = false"
               >
                 Close
               </v-btn>
             </template>
           </v-snackbar>
         </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <input class="form-control" placeholder="Write a message" v-model="body"/>
    </form>
  </div>
</template>
<script>
import pushSubscription from '@/components/vc-push/pushSubscription';
import { Send } from '@core/api/Chat';
import { onMounted, ref, watch } from '@vue/composition-api';
import Pusher from 'pusher-js';
export default {
  name: 'App',
  setup() {
    const snackbar = ref(false)
    const username = ref('username');
    const messages = ref([]);
    const body = ref('');
    const {
      checkUrl
    } = pushSubscription()


    const channelSubscribe = () => {
      let result = checkUrl()
      Pusher.logToConsole = true;
        const pusher = new Pusher('01b4d5c8507d46578a54', {
        cluster: 'ap2'
        });
      if(result == false) {
        pusher.disconnect();
      }
      else {
        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', data => {
          console.log('dsigidgeyyyvb', data)
        messages.value.push(data);
        fetchOrders()
      });
      }
    }
    onMounted(() => {
      channelSubscribe()
    })
      watch(messages, () => {
        snackbar.value = true
  })
    const submit = async () => {
      await Send({
          username: username.value,
          body: body.value
      })
      body.value = '';
    }
    return {
      channelSubscribe,
      checkUrl,
      snackbar,
      username,
      messages,
      body,
      submit
    }
  }
}
</script>

<style>
.scrollarea {
  min-height: 500px;
}
</style>
